import {Component, ElementRef, HostListener, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import videojs from 'video.js';
import { DataService } from '../../services/data.service';
import { MixpanelService } from '../../services/mixpanel.service';
import { VideoService } from 'src/app/services/video.service';
import { Clip } from 'src/app/models/clip';

@Component({
  selector: 'app-vjs-player',
  templateUrl: './video.component.html',
  styleUrls: ['./video.component.scss'],
})
export class VideoComponent implements OnInit, OnDestroy {
  
  @ViewChild('target', {static: true}) target: ElementRef | undefined;
  @Input() asset!: Clip;
  @Input() isMain = false;
  player = {} as videojs.Player;

  @HostListener('window:keydown', ['$event']) handleKeyDown(event: KeyboardEvent) {
    let dir, vol: number | undefined;
    switch(event.code){
      case "ArrowLeft": dir = -5; break;
      case "ArrowRight": dir = 5; break;
      case "ArrowDown": vol = -0.1; break;
      case "ArrowUp": vol = 0.1; break;
      case "Space": this.togglePlay(); break;
    }
    if(dir){
      this.player.userActive(true);
      this.player.currentTime(this.player.currentTime() + dir);
    }
    if(vol){
      this.player.userActive(true);
      this.player.volume(this.player.volume() + vol);
    }
  }

  constructor(
    public dataService: DataService, 
    private mixpanelService: MixpanelService,
    public videoService: VideoService
  ){}

  ngOnInit(){
    let videoOptions = this.videoService.setVideoOptions(this.asset.clip_url, this.asset.clip_format || 'mp4');
    
    this.player = videojs(this.target?.nativeElement, videoOptions, function onPlayerReady(){});
    this.player.on('error', (err) => {
      this.dataService.assetCorrupted.push(this.asset.clip_url);
      this.reportToMixpanel(err.target.player.error_);
    });
    setTimeout(() => this.videoService.videoOnload = true, 3000);
  }

  ngOnDestroy() {
    if(this.player) this.player.dispose();
  }

  togglePlay(){
    this.player.paused() ? this.player.play() : this.player.pause();
  }

  reportToMixpanel(vjsError: any){
    this.mixpanelService.track('Video Error in Landing', {
      type: "video",
      clip: this.asset,
      params: this.dataService.params,
      videojsError: vjsError
    });
  }

}
