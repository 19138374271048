import { Injectable, Renderer2, RendererFactory2 } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class ColorService {

  private renderer: Renderer2;
  colorTheme = "light-theme";

  constructor(rendererFactory: RendererFactory2) {
    this.renderer = rendererFactory.createRenderer(null, null);
    this.detectSystemTheme();
  }

  detectSystemTheme() {
    const prefersDarkScheme = window.matchMedia('(prefers-color-scheme: dark)').matches;
    this.colorTheme = prefersDarkScheme ? 'dark-theme' : 'light-theme';
  }

  initTheme() {
    this.renderer.addClass(document.body, this.colorTheme);
  }
  
  setDarkTheme() {
    this.colorTheme = 'dark-theme';
    this.renderer.removeClass(document.body, "light-theme");
    this.renderer.addClass(document.body, "dark-theme");
  }

  setLightTheme() {
    this.colorTheme = 'light-theme';
    this.renderer.removeClass(document.body, "dark-theme");
    this.renderer.addClass(document.body, "light-theme");
  }

}