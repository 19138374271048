<div class="carouselCont" *ngIf="dataService.carousel[0]">
  <div class="carouselTitle">{{ 'relatedAssets' | translate }}</div>

  <div class="tableAssets" #feedContainer>
    <div class="assetContainer" *ngFor="let asset of dataService.carousel">
      <div class="assetFeed">

        <div *ngIf="asset.material_type === 'Video'" class="videoCont">
          <app-vjs-player [asset]="asset" *ngIf="!dataService.assetCorrupted.includes(asset.clip_url)"></app-vjs-player>
          <div class="assetCorrupted" *ngIf="dataService.assetCorrupted.includes(asset.clip_url)">
            <mat-icon>movie</mat-icon></div>
        </div>

        <div *ngIf="asset.material_type === 'Audio'" class="audioCont">
          <audio controls #audio *ngIf="!dataService.assetCorrupted.includes(asset.clip_url)">
            <source [src]="asset.clip_url">
          </audio>
          <div class="assetCorrupted" *ngIf="dataService.assetCorrupted.includes(asset.clip_url)">
            <mat-icon>radio</mat-icon></div>
        </div>

        <div *ngIf="asset.material_type === 'Image'">
          <div class="imgCont" *ngIf="!dataService.assetCorrupted.includes(asset.clip_url)">
            <img [src]="asset.thumb_url">
          </div>
          <div *ngIf="dataService.assetCorrupted.includes(asset.clip_url)"><mat-icon>image</mat-icon></div>
        </div>

      </div>

      <div class="assetContent">
        <div style="display: flex; justify-content: space-between">
          <div class="text-sm bold twoLines">{{ asset.name }}</div>
          <button class="button" [matMenuTriggerFor]="landingMenu">
            <mat-icon>more_vert</mat-icon>
          </button>
        </div>

        <mat-menu #landingMenu="matMenu">
          <button mat-menu-item>
            <a [href]="asset.landing" target="_blank" style="display: flex; align-items: center">
              <mat-icon>open_in_new</mat-icon>
              <span class="link">{{ 'openNewWindow' | translate }}</span>
            </a>
          </button>
        </mat-menu>

        <div class="entity twoLines">
          {{ asset.entity }}
        </div>
      </div>
    </div>

    <mat-icon class="arrowScrollLeft" *ngIf="(feedContainer.scrollLeft > 0)"
      (click)="feedContainer.scrollLeft = feedContainer.scrollLeft - 700">
      arrow_circle_left
    </mat-icon>

    <mat-icon *ngIf="feedContainer.scrollLeft < feedContainer.scrollWidth - feedContainer.clientWidth"
      class="arrowScrollRight" (click)="feedContainer.scrollLeft = feedContainer.scrollLeft + 700">
      arrow_circle_right
    </mat-icon>
  </div>
</div>