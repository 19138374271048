<div class="lottie" *ngIf="dataService.loader > 0">
  <ng-lottie class="lot" [options]="lottieOptions"></ng-lottie>
</div>

<div class="container" *ngIf="dataService.loader === 0">
  <div class="main">
    <div class="header">
      <a target="_blank" matTooltip="{{ 'goToPage' | translate }}" href="https://seenka.com/">
        <img id="logoSeenka" [src]="getSeenkaIcon()">
      </a>

      <div class="imgCont">
        <img *ngIf="dataService.clip.workspace_icon" [src]="dataService.clip.workspace_icon"
          (error)="iconError($event)">
      </div>
    </div>

    <div class="body" [ngSwitch]="dataService.clip.material_type">
      <div *ngSwitchCase="'Video'">
        <app-vjs-player *ngIf="!dataService.assetCorrupted"
          [videoOptions]="videoService.videoOptions"></app-vjs-player>
        <div class="assetCorrupted" *ngIf="dataService.assetCorrupted"><mat-icon>movie</mat-icon></div>
      </div>
      <div *ngSwitchCase="'Audio'">
        <app-audio *ngIf="!dataService.assetCorrupted"></app-audio>
        <div class="assetCorrupted" *ngIf="dataService.assetCorrupted"><mat-icon>radio</mat-icon></div>
      </div>
      <div *ngSwitchCase="'Image'">
        <div class="imageCont">
          <img *ngIf="!dataService.assetCorrupted" class="image" [src]="dataService.clip.clip_url"
            (load)="setWidthAsImage($event)" (error)="dataService.assetCorrupted = true">
          <div *ngIf="dataService.assetCorrupted"><mat-icon>image</mat-icon></div>
        </div>
      </div>
      <div *ngSwitchCase="'Text'">
        <div style="height: 50px"></div>
      </div>
    </div>

    <div class="content" *ngIf="!dataService.error">
      <h3 class="title">{{ dataService.clip.name }}</h3>

      <div class="subtitleBox">
        <div class="entitiesBox">
          <span class="entities" [style.max-height]="seeMore ? 'inherit' : '50px'" *ngIf="dataService.clip.entities">
            <div class="entity" [class.entityLimits]="getEntities()[1]" *ngFor="let entity of getEntities()">
              <div class="imgCont">
                <img [src]="entity.thumb_url" imageError iconName="person" addClass="errorIcon">
              </div>
              <div class="entityBody">
                <span style="margin: 3px 5px -2px 5px">{{ entity.name }}</span>
                <span class="adds" style="margin-bottom: 0"
                  *ngIf="entity.sector && !['null', '-'].includes(entity.sector) ">{{ entity.sector }}</span>
              </div>
            </div>
          </span>
          <mat-icon class="seeMore" style="cursor: pointer" (click)="seeMore = !seeMore" *ngIf="getEntities() && getEntities()[3]">
            {{ seeMore ? 'expand_less' : 'expand_more' }}
          </mat-icon>
  
          <div class="buttons">
            <div class="button" (click)="shareMaterial()">
              <mat-icon>share</mat-icon>
              <span style="margin: 0 5px" class="largeScreen">{{ 'share' | translate }}</span>
            </div>
  
            <div class="button" (click)="downloadMaterial()">
              <mat-icon *ngIf="!videoService.downloading.loading">download</mat-icon>
              <mat-progress-spinner *ngIf="videoService.downloading.loading"
                [mode]="videoService.downloading.value > 0 ? 'determinate' : 'indeterminate'"
                [value]="videoService.downloading.value" [diameter]="20">
              </mat-progress-spinner>
              <span style="margin: 0 5px" class="largeScreen">{{ 'download' | translate }}</span>
            </div>
  
            <div class="button" (click)="redirect()"
              *ngIf="(dataService.clip.destination_url && dataService.clip.destination_url !== '')">
              <mat-icon>open_in_new</mat-icon>
              <span style="margin: 0 5px" class="largeScreen">{{ 'redirect' | translate }}</span>
            </div>
  
            <mat-icon class="button"
              (click)="colorService.colorTheme === 'dark-theme' ? setLightTheme() : setDarkTheme()"
              [matTooltip]="getTooltip()" matTooltipShowDelay="500">{{ colorService.colorTheme === 'dark-theme' ?
              'light_mode' : 'dark_mode' }}
            </mat-icon>
          </div>
        </div>
      </div>
  
      <div class="adds" >
        <div style="display: flex">
          <span class="add" *ngIf="dataService.clip?.media_name || dataService.clip?.media?.name">{{ dataService.clip.media_name }}</span>
          <span style="margin: 0 8px" *ngIf="dataService.clip?.program && (dataService.clip?.media_name || dataService.clip?.media?.name)">|</span>
          <span class="add oneLine" *ngIf="dataService.clip?.program">{{ dataService.clip.program }}</span>
        </div>
        <span *ngIf="dataService.params.type === 'airing'">{{ dataService.clip.airing_time | date: 'dd/MM/yy, hh:mm a' }}</span>
        <span *ngIf="dataService.params.type === 'asset'">{{ 'initDate' | translate }}: {{ dataService.clip.start_time | date: 'dd/MM/yy' }}</span>        
        <div class="add oneLine" *ngIf="dataService.clip?.author">{{ dataService.clip.author }}</div>
<!--    <div class="add oneLIne" *ngIf="dataService.clip?.brand && dataService.clip?.brand !== 'Marca Pendiente'">{{ dataService.clip.brand }}</div>
        <div class="add" *ngIf="dataService.clip?.format">{{ dataService.clip.format }}</div>
        <div class="add" *ngIf="dataService.clip?.support">{{ dataService.clip.support }}</div> -->
      </div>
  
      <div class="description" *ngIf="dataService.clip.asset_description">
        <span>{{dataService.clip.asset_description}}</span>
      </div>
      <div class="AIgenerated" *ngIf="dataService.clip.asset_description">{{ 'AIgenerated' | translate }}</div>
    </div>

    <div class="error" *ngIf="dataService.error" [ngSwitch]="dataService.error">
      <div class="errorMessage oops" *ngSwitchCase="'not_found'">
        <h1>{{ 'assetNotAvailable' | translate }}</h1>
        <p>{{ 'assetNotAvailableHelp' | translate }}</p>
      </div>

      <div class="errorMessage oops" *ngSwitchCase="'bad_request'">
        <h1>{{ 'invalidURL' | translate }}</h1>
        <p>{{ 'invalidURLHelp' | translate }}</p>
      </div>

      <div class="errorMessage oops" *ngSwitchDefault>
        <h1>{{ 'serverError' | translate }}</h1>
        <p>{{ 'serverErrorHelp1' | translate }}<br>{{ 'serverErrorHelp2' | translate }}</p>
      </div>
    </div>
  </div>
</div>