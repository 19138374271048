
<div [hidden]="!videoOnload">
  <video #target
    (loadedmetadata)="videoOnload = true"
    class="video-js vjs-big-play-centered vjs-16-9"
    [poster]="dataService.clip.thumb_url"
    [currentTime]="dataService.clip.offset || 0"
    playsinline
    data-setup='{ "playbackRates": [0.5, 0.75, 1, 1.5, 1.75, 2], "fluid": true }'
    preload="auto">
  </video>
</div>

<div *ngIf="!videoOnload" class="spinner">
  <mat-spinner [diameter]="50"></mat-spinner>
</div>

