<div class="lottie" *ngIf="dataService.loader > 0">
  <ng-lottie class="lot" [options]="lottieOptions"></ng-lottie>
</div>

<div class="container" *ngIf="dataService.loader === 0">
  <div class="main">
    <div class="header">
      <a target="_blank" matTooltip="{{ 'goToPage' | translate }}" href="https://seenka.com/">
        <img id="logoSeenka" [src]="getSeenkaIcon()">
      </a>

      <div class="imgCont">
        <img *ngIf="dataService.clip.workspace_icon" [src]="dataService.clip.workspace_icon"
          (error)="iconError($event)">
      </div>
    </div>

    <app-landscape *ngIf="dataService.orientation === 'landscape'"></app-landscape>
    <app-portrait *ngIf="dataService.orientation === 'portrait'"></app-portrait>

    <app-carousel *ngIf="dataService.orientation !== 'portrait'"></app-carousel>

    <div class="error" *ngIf="dataService.error" [ngSwitch]="dataService.error">
      <div class="errorMessage oops" *ngSwitchCase="'not_found'">
        <h1>{{ 'assetNotAvailable' | translate }}</h1>
        <p>{{ 'assetNotAvailableHelp' | translate }}</p>
      </div>

      <div class="errorMessage oops" *ngSwitchCase="'bad_request'">
        <h1>{{ 'invalidURL' | translate }}</h1>
        <p>{{ 'invalidURLHelp' | translate }}</p>
      </div>

      <div class="errorMessage oops" *ngSwitchDefault>
        <h1>{{ 'serverError' | translate }}</h1>
        <p>{{ 'serverErrorHelp1' | translate }}<br>{{ 'serverErrorHelp2' | translate }}</p>
      </div>
    </div>

    <div class="footer">© Seenka <span>{{ year }}</span></div>
  </div>
</div>