import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { DataService } from '../services/data.service';
import { LanguageService } from '../services/language.service';
import { VideoService } from '../services/video.service';
import { ColorService } from '../services/color.service';
import { TranslateService } from '@ngx-translate/core';
import { AnimationOptions } from 'ngx-lottie';
import { Clipboard } from '@angular/cdk/clipboard';
import { SnackbarService } from '../components/snackbar/snackbar.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

  lottieOptions: AnimationOptions = { path: '/assets/lottie/loaderSeenka.json' };
  year = new Date().getFullYear();
  
  constructor(
    private translate: TranslateService,
    public colorService: ColorService,
    public videoService: VideoService,
    public dataService: DataService,
    public languageService: LanguageService,

  ) { }

  ngOnInit(): void {
    this.dataService.getData();

    this.dataService.clip$.subscribe((clip) => {
      if (clip.material_type === 'Video') {
        this.videoService.setVideoOptions(clip.clip_url, clip.clip_format);
      }
    });
  }

  getSeenkaIcon() {
    return this.colorService.colorTheme === 'light-theme' ?
      'assets/Seenka.svg' : 'assets/seenka-white.svg';
  }

  setLightTheme() {
    this.colorService.setLightTheme()
  }

  setDarkTheme() {
    this.colorService.setDarkTheme()
  }

  getTooltip() {
    let mode = this.colorService.colorTheme === 'dark-theme' ? 'lightMode' : 'darkMode';
    return this.translate.instant('settings.' + mode);
  }

  iconError(event: any) {
    let el = event.target as HTMLElement;
    const parent = el.parentNode;
    if (!parent) return
    parent.removeChild(el);

    const newElement = document.createElement('div');
    newElement.classList.add('material-icons');
    newElement.textContent = 'work';

    let firstChild = parent.firstChild
    if (firstChild) parent.insertBefore(newElement, parent.firstChild);
    else parent.appendChild(newElement);
  }

  expandAsset(landing: string) {
    console.log(landing);

    // landing = landing.replace(origin = "Report", origin = "Platform")
  }

}
