import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { environment } from "src/environments/environment";
import { SnackbarService } from '../snackbar/snackbar.service';
import { Clip, Entity } from '../models/clip';
import { QueryParams } from '../models/queryParams';
import { LanguageService } from './language.service';
import { MixpanelService } from './mixpanel.service';
import { Subject } from 'rxjs';
import { AiringsService } from './airings.service';
import * as moment from 'moment';

@Injectable({ providedIn: 'root' }) export class DataService {

  clip = {} as Clip;
  clip$: Subject<any> = new Subject();

  extensions = {
    Video: ['mp4', 'm3u8'],
    Audio: ['mp3'],
    Image: ['JPG', 'PNG']
  } as any;

  params = {} as QueryParams;
  loader: boolean | undefined;
  error: any = null;
  assetCorrupted = false;
  shortUrl = window.location.href;

  constructor(
    private mixpanelService: MixpanelService,
    private translateService: TranslateService,
    private languageService: LanguageService,
    private snackBar: SnackbarService,
    private route: ActivatedRoute,
    private http: HttpClient,
    private airingsService: AiringsService
  ) { }

  getData() {
    this.setInitParams();

    if (this.params.redirectTo) {
      this.mixpanelService.setParams(this.params);
      window.location.href = decodeURIComponent(this.params.redirectTo);
      return;
    }

    this.http.get(environment.gateway + 'airings/' + this.params.type + '/' + this.params.id).subscribe({
      next: (res: any) => {
        if (this.clip.clip_url === undefined) this.dataReceived(res);
      },
      error: (err) => {
        if (!this.loader && this.clip.clip_url === undefined) this.setError(err);
      }
    });
    
    if (this.params.type === 'asset') this.getAsset();
    this.getShortLink();
  };

  dataReceived(res: any) {
    let formats = ["Nota", "Tweet", "Instagram Post", "Facebook Post", "Youtube Post", 'TikTok Post']
    if (formats.includes(res.format) && res.destination_url) window.location.href = res.destination_url;
    else {
      this.clip = res as Clip;
      if (!this.clip.material_type) this.setMaterialType();
      if (this.clip.material_type === "Image") this.clip.clip_url = this.clip.thumb_url;
      this.clip$.next(this.clip);
      this.getWsIcon();
      this.setInit();
    }
  }

  setMaterialType() {
    this.clip.material_type = Object.keys(this.extensions)
      .find(key => this.extensions[key]
        .find((fk: string) => this.clip.clip_url?.includes(fk))) || 'Text';
  }

  setInitParams() {
    this.loader = true;
    this.params = this.route.snapshot.queryParams as QueryParams;
    this.languageService.setTranslate(this.params.lang);
    this.mixpanelService.init(this.params.email);
    setTimeout(() => {
      if (this.loader) this.snackBar.advice(this.translateService.instant('loadingSlow'));
    }, 5000);
  }

  setError(err: any) {
    this.mixpanelService.track('Landing Error', err);
    switch (err.status) {
      case 403: this.error = 'not_found'; break;
      case 404: this.error = 'bad_request'; break;
      default: this.error = 'error';
    }
    this.setInit();
  }

  setInit() {
    if (!this.error) this.snackBar.close();
    this.languageService.currentLang = this.translateService.currentLang;
    this.loader = false;
  }

  getWsIcon() {
    let wsId = this.params.new_workspace_id || this.params.workspace_id;
    this.http.get(environment.gateway + 'workspace/logo/' + wsId).subscribe({
      next: (res: any) => {
        this.clip.workspace_icon = res.logo_url;
      },
      error: (err) => this.mixpanelService.track('Landing WorkspaceIcon Error', err)
    });
  }

  getShortLink() {
    let url = window.location.href;
    this.http.post(environment.gateway + 'dataset/shorten-link/', { url })
      .subscribe((res: any) => this.shortUrl = res.shortURL);
  }

  gate = true;
  getAsset(startTime = this.params.start_time, endTime = this.params.end_time) {
    if (!startTime) startTime = moment().subtract(4, 'years').format('YYYY-MM-DD');
    if (!endTime) endTime = moment().format('YYYY-MM-DD');
    let queryGeneral = this.airingsService.getGeneralData(this.params.id, startTime, endTime) as any;
    this.http.post<any[]>(environment.gateway + 'airings/', queryGeneral).subscribe({
      next: (res) => {
        if (res.length > 0) {
          this.getAuthor(startTime, endTime);
          if (this.clip.clip_url) return;
          this.dataReceived(res[0]);
          this.getStartTime(startTime, endTime);
          this.getEntities(startTime, endTime);
        } else if (this.gate) {
          this.gate = false;
          this.getAsset('', '');
        } else if (this.clip.clip_url === undefined) this.setError({ status: 403 });
      },
      error: (err) => {
        this.mixpanelService.track('Landing Error', err);
        if (!this.loader && this.clip.clip_url === undefined) this.setError(err);
      }
    });
  }

  getStartTime(startTime: string, endTime: string) {
    let queryStartTime = this.airingsService.getStartTime(this.params.id, startTime, endTime) as any;
    queryStartTime.order = "start_time";
    queryStartTime.asc = "true";
    this.http.post(environment.gateway + 'airings/', queryStartTime).subscribe((res: any) => {
      this.clip.start_time = res[0].start_time;
    });
  }

  getEntities(startTime: string, endTime: string) {
    let queryEntities = this.airingsService.getEntities(this.params.id, startTime, endTime);
    queryEntities.size = 100;
    this.http.post(environment.gateway + 'airings/', queryEntities).subscribe((res: any) => {
      this.clip.entities = res.sort((a: Entity, b: Entity) => a.name.localeCompare(b.name));
    });
  }

  getAuthor(startTime: string, endTime: string) {
    let queryAuthor = this.airingsService.getAuthor(this.params.id, startTime, endTime);
    this.http.post(environment.gateway + 'airings/', queryAuthor).subscribe((res: any) => {
      this.clip.author = res[0].name;
    });
  }

}
