import { Component, ElementRef, ViewChild } from '@angular/core';
import { DataService } from 'src/app/services/data.service';
import { SnackbarService } from '../snackbar/snackbar.service';
import { TranslateService } from '@ngx-translate/core';
import { Clipboard } from '@angular/cdk/clipboard';
import { VideoService } from 'src/app/services/video.service';
import { ColorService } from 'src/app/services/color.service';

@Component({
  selector: 'app-landscape',
  templateUrl: './landscape.component.html',
  styleUrls: ['./landscape.component.scss']
})
export class LandscapeComponent {

  @ViewChild('keywordsCont') keywordsCont: ElementRef | undefined;

  seeMoreEntities = false;
  seeMoreKeywords = false;

  constructor(
    public videoService: VideoService,
    public dataService: DataService,
    private clipboard: Clipboard,
    private snackbar: SnackbarService,
    private translate: TranslateService,
    private colorService: ColorService
  ) { }

  setWidthAsImage(event: any) {
    const elementWidth = event.target.width;
    const width = Math.max(600, elementWidth);
    const mainContainer = document.getElementsByClassName('main')[0];
    mainContainer.setAttribute('style', `width: ${width}px`);
  }

  setAssetCorrupted() {
    this.dataService.assetCorrupted.push(this.dataService.clip.clip_url)
  }

  shareMaterial() {
    this.clipboard.copy(this.dataService.shortUrl);
    this.snackbar.success(this.translate.instant('copiedUrl'));
  }

  downloadMaterial() {
    this.dataService.clip.clip_format === 'm3u8'
      ? this.videoService.convertToMp4()
      : this.videoService.download(this.dataService.clip.clip_url);
  }

  redirect() {
    window.open(this.dataService.clip.destination_url, '_blank');
  }

  goToWhatsapp() {
    let url = window.innerWidth > 800
      ? "https://web.whatsapp.com/send?phone=5491124894000&text="
      : "https://api.whatsapp.com/send?phone=5491124894000&text="

    let text = "Hola, quiero más información sobre Seenka!";
    window.open(url + text, "_blank");
  }

  isDarkTheme() {
    return this.colorService.colorTheme === 'dark-theme';
  }

  showKeywordsBtt() {
    let keys = this.keywordsCont?.nativeElement.children;
    if (keys) {
      let keywordsWidth = Array.from(keys).reduce((acc, child: any) => {
        const childWidth = child.offsetWidth + 10 || 0;
        return acc + childWidth;
      }, 0) as number;
      keywordsWidth += 2;
      let totalWidth = Math.min(window.innerWidth, 800);
      return totalWidth < keywordsWidth + 10;
    }
    return false;
  }

}
