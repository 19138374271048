<div class="body" [ngSwitch]="dataService.clip.material_type">
  <div *ngSwitchCase="'Video'">
    <app-vjs-player *ngIf="!dataService.assetCorrupted.includes(dataService.clip.clip_url)" [asset]="dataService.clip" [isMain]="true"></app-vjs-player>
    <div class="assetCorrupted" *ngIf="dataService.assetCorrupted.includes(dataService.clip.clip_url)">
      Video not Available
      <mat-icon>movie</mat-icon>
    </div>
  </div>
  <div *ngSwitchCase="'Audio'">
    <app-audio *ngIf="!dataService.assetCorrupted.includes(dataService.clip.clip_url)"></app-audio>
    <div class="assetCorrupted" *ngIf="dataService.assetCorrupted.includes(dataService.clip.clip_url)">
      Audio not Available
      <mat-icon>radio</mat-icon>
    </div>
  </div>
  <div *ngSwitchCase="'Image'">
    <div class="imageCont">
      <img *ngIf="!dataService.assetCorrupted.includes(dataService.clip.clip_url)" class="image" [src]="dataService.clip.clip_url"
        (load)="setWidthAsImage($event)" (error)="setAssetCorrupted()">
      <div class="assetCorrupted" *ngIf="dataService.assetCorrupted.includes(dataService.clip.clip_url)">
        Image not Available
        <mat-icon>image</mat-icon>
      </div>
    </div>
  </div>
  <div *ngSwitchCase="'Text'">
    <div style="height: 50px"></div>
  </div>

  <div *ngSwitchCase="'Embed'">
    <div class="videoContYouTube"  *ngIf="dataService.clip.format.toLowerCase().includes('youtube')">
      <div class="assetCorrupted" *ngIf="!dataService.clip.clip_url"><mat-icon>movie</mat-icon></div>
      <iframe
        width="560"
        height="315"
        [src]="dataService.clip.clip_url"
        frameborder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowfullscreen>
      </iframe>
    </div>

    <div class="videoContTikTok"  *ngIf="dataService.clip.format.toLowerCase().includes('tiktok')">
      <div class="assetCorrupted" *ngIf="!dataService.clip.clip_url"><mat-icon>movie</mat-icon></div>
      <iframe
        [src]="dataService.clip.clip_url"
        frameborder="0"
        allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"
        allowfullscreen
        scrolling="yes">
      </iframe>
    </div>
  </div>
</div>

<div class="content" *ngIf="!dataService.error">
  <h3 class="title">{{ dataService.clip.name }}</h3>

  <div class="subtitleBox">
    <div class="entitiesBox">
      <span class="entities" [style.max-height]="seeMoreEntities ? 'inherit' : '50px'"
        *ngIf="dataService.clip.entities">
        <div class="entity" [class.entityLimits]="dataService.clip.entities[1]" *ngFor="let entity of dataService.clip.entities">
          <div class="imgCont">
            <img [src]="entity.thumb_url" imageError iconName="person" addClass="errorIcon">
          </div>
          <div class="entityBody">
            <span style="margin: 3px 5px -2px 5px">{{ entity.name }}</span>
            <span class="adds" style="margin-bottom: 0"
              *ngIf="entity.sector && !['null', '-'].includes(entity.sector) ">{{ entity.sector }}</span>
          </div>
        </div>
      </span>
      <mat-icon class="seeMore" style="cursor: pointer" (click)="seeMoreEntities = !seeMoreEntities"
        *ngIf="dataService.clip.entities && dataService.clip.entities[3]">
        {{ seeMoreEntities ? 'expand_less' : 'expand_more' }}
      </mat-icon>
    </div>
  </div>

  <div class="keywordsCont" #keywordsCont [style.height]="seeMoreKeywords ? 'auto' : '40px'">

    <div class="buttons">
      <div class="button" (click)="shareMaterial()">
        <mat-icon>share</mat-icon>
        <span style="margin: 0 5px" class="largeScreen">{{ 'share' | translate }}</span>
      </div>

      <div class="button" (click)="downloadMaterial()">
        <mat-icon *ngIf="!videoService.downloading.loading">download</mat-icon>
        <mat-progress-spinner *ngIf="videoService.downloading.loading"
          [mode]="videoService.downloading.value > 0 ? 'determinate' : 'indeterminate'"
          [value]="videoService.downloading.value" [diameter]="20">
        </mat-progress-spinner>
        <span style="margin: 0 5px" class="largeScreen">{{ 'download' | translate }}</span>
      </div>

      <div class="button" (click)="redirect()"
        *ngIf="(dataService.clip.destination_url && dataService.clip.destination_url !== '')">
        <mat-icon>open_in_new</mat-icon>
        <span style="margin: 0 5px" class="largeScreen">{{ 'redirect' | translate }}</span>
      </div>

      <button class="button bttTalkExpert" (click)="goToWhatsapp()">
        <img *ngIf="!isDarkTheme()" src="assets/whatsappDark.webp" class="isWebp">
        <img *ngIf="isDarkTheme()" src="assets/whatsapp.png" class="isPng">
        <span class="largeScreen">&nbsp;&nbsp;{{ 'talkToAnExpert' | translate }}</span>
      </button>

      <!--             <mat-icon class="button"
        (click)="colorService.colorTheme === 'dark-theme' ? setLightTheme() : setDarkTheme()"
        [matTooltip]="getTooltip()" matTooltipShowDelay="500">{{ colorService.colorTheme === 'dark-theme' ?
        'light_mode' : 'dark_mode' }}
      </mat-icon> -->
    </div>
    
    <div class="keyword" *ngFor="let keyword of dataService.clip.keywords">
      {{ keyword }}
    </div>
    <mat-icon class="seeMoreKeywords" style="cursor: pointer" (click)="seeMoreKeywords = !seeMoreKeywords"
      *ngIf="showKeywordsBtt()">
      {{ seeMoreKeywords ? 'expand_less' : 'expand_more' }}
    </mat-icon>
  </div>

  <div class="adds">
    <div style="display: flex">
      <span class="add" *ngIf="dataService.clip?.media_name || dataService.clip?.media?.name">{{
        dataService.clip.media_name }}</span>
      <span style="margin: 0 8px"
        *ngIf="dataService.clip?.program && (dataService.clip?.media_name || dataService.clip?.media?.name)">|</span>
      <span class="add oneLine" *ngIf="dataService.clip?.program">{{ dataService.clip.program }}</span>
    </div>
    <span *ngIf="dataService.params.type === 'airing'">{{ dataService.clip.airing_time | date: 'dd/MM/yy, hh:mm a'
      }}</span>
    <span *ngIf="dataService.params.type === 'asset'">{{ 'initDate' | translate }}: {{ dataService.clip.start_time |
      date: 'dd/MM/yy' }}</span>
    <!--    <div class="add oneLine" *ngIf="dataService.clip?.author">{{ dataService.clip.author }}</div>
    <div class="add oneLIne" *ngIf="dataService.clip?.brand && dataService.clip?.brand !== 'Marca Pendiente'">{{ dataService.clip.brand }}</div>
    <div class="add" *ngIf="dataService.clip?.format">{{ dataService.clip.format }}</div>
    <div class="add" *ngIf="dataService.clip?.support">{{ dataService.clip.support }}</div> -->
  </div>

  <div class="description" *ngIf="dataService.clip.asset_description">
    <span>{{dataService.clip.asset_description}}</span>
  </div>
  <div class="AIgenerated" *ngIf="dataService.clip.asset_description">{{ 'AIgenerated' | translate }}</div>

  <app-carousel></app-carousel>
</div>