import { Directive, ElementRef, Inject, Input, Optional } from '@angular/core';

@Directive({ selector: 'img[imageError]' }) export class ImageErrorDirective {

  @Input('iconName') iconName!: string;
  @Input('addClass') addClass!: string;

  constructor(private el: ElementRef){
    this.el.nativeElement.onerror = () => {
      const parent = el.nativeElement.parentNode;
      if(!parent) return
      parent.removeChild(el.nativeElement);

      const newElement = document.createElement('div');
      newElement.classList.add('material-icons');
      if(this.addClass) this.addClass.split(' ').forEach(addCl => newElement.classList.add(addCl));
      newElement.textContent = this.iconName;
      let firstChild = parent.firstChild
      if(firstChild) parent.insertBefore(newElement, parent.firstChild);
      else parent.appendChild(newElement);
    };
  }
}
